import { Typography } from '@mui/material';

interface EmptyResultProps {
  content?: React.ReactNode;
}

export const EmptyResult: React.FC<EmptyResultProps> = ({ content }) => {
  return (
    <Typography
      variant="body2"
      component="div"
    >
      {content}
    </Typography>
  );
};
