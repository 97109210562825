import { Box } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { TextField } from 'react-admin';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import { tokens } from 'src/locales/tokens';

export const AgencyFields: React.FC = () => {
  return (
    <Box flexBasis="25%">
      <TopLabelField label={t(tokens.domains.members.detail.label.signatoryAgency)}>
        <TextField source={'club.agencyName'} />
      </TopLabelField>
    </Box>
  );
};
