import { format, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';

/**
 * Convertit une heure en format HH:mm en une date-heure au format ISO 8601 avec le décalage horaire local de l'utilisateur.
 *
 * @param {string} time - L'heure d'entrée au format "HH:mm" (ex: "12:32").
 * @returns {string} - La chaîne de date-heure formatée en ISO 8601 avec le décalage horaire local.
 *
 * @example
 * Utilisateur en France (UTC+1)
 * const result = hourToGridFormatLocalUser("12:32");
 * // Sortie : "2024-11-20T12:32:00+01:00"
 *
 * @example
 * Utilisateur en Jamaïque (UTC-5)
 * const result = hourToGridFormatLocalUser("12:32");
 * // Sortie : "2024-11-20T12:32:00-05:00"
 */
export const hourToGridFormatLocalUser = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const now = new Date();
  const date = setMilliseconds(setSeconds(setMinutes(setHours(now, hours), minutes), 0), 0);
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
};
