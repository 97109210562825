import Box from '@mui/material/Box';

interface UseStartIconProps {
  depth: number;
  active: boolean;
  icon?: JSX.Element;
}

/**
 * Custom hook that returns the start icon element based on the depth.
 *
 * @param {UseStartIconProps} props - The props object containing the depth, active, and icon values.
 * @returns {JSX.Element} - The JSX element representing the start icon.
 */
const useStartIcon = ({ depth, active, icon }: UseStartIconProps): JSX.Element => {
  if (depth > 0) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 20,
          justifyContent: 'center',
          width: 20,
        }}
      >
        <Box
          sx={{
            backgroundColor: active
              ? 'var(--nav-item-icon-active-color)'
              : 'var(--nav-item-icon-color)',
            borderRadius: '50%',
            height: active ? 6 : 4,
            opacity: active ? 1 : 0,
            width: active ? 6 : 4,
          }}
        />
      </Box>
    );
  }
  return icon || <></>;
};

export default useStartIcon;
