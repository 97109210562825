import { LocationOn } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { AddressFeature } from 'src/domains/club/contract/types/postalAddress.types';

interface AddressSearchResultProps {
  address: AddressFeature;
}

const AddressSearchResult: React.FC<AddressSearchResultProps> = ({ address }) => {
  return (
    <Grid
      container
      sx={{ alignItems: 'center' }}
    >
      <Grid
        item
        sx={{ display: 'flex', width: 44 }}
      >
        <LocationOn sx={{ color: 'text.secondary' }} />
      </Grid>
      <Grid
        item
        sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
      >
        <Typography
          fontWeight={'bold'}
          component={'span'}
        >
          {address.properties.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {address.properties.postcode} - {address.properties.city}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddressSearchResult;
