import { Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { HeaderSkeleton } from 'src/common/components/detail/HeaderSkeletons';
import { ImportExportButtons } from 'src/common/components/ImportExportButtons';
import { HeaderLayout } from 'src/common/layouts/react-admin/HeaderLayout';
import { ListExportModal } from 'src/common/modals/export/ListExportModal';

interface HeaderProps {
  name?: string | null;
  hasExport: boolean;
  resource: string;
}

export const Header: React.FC<HeaderProps> = ({ name, hasExport, resource }) => {
  const record = useRecordContext();

  if (!record) {
    return <HeaderSkeleton />;
  }

  return (
    <HeaderLayout>
      <>
        <Typography variant="h4">{`${name}`}</Typography>
      </>
      <>
        {hasExport && <ImportExportButtons />}

        {hasExport && <ListExportModal resource={resource} />}
      </>
    </HeaderLayout>
  );
};
