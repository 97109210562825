import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box, Card, Chip, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { FunctionField, TextField, useRecordContext } from 'react-admin';
import { Member } from 'src/admin-api-types';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import { LabeledField } from 'src/components/react-admin/core/fields/layout/LabeledField';
import { tokens } from 'src/locales/tokens';
import { dateToGridFormat } from 'src/utils/dateToGridFormat';
import { isSignatory } from 'src/utils/isSignatory';
import { translateContractStatus } from 'src/utils/translateContractStatus';
import { translatePaymentPeriod } from 'src/utils/translatePaymentPeriod';
import { translatePaymentType } from 'src/utils/translatePaymentType';

import { CardTitle } from '../common/CardTitle';
import { AgencyFields } from '../fields/AgencyFields';
import { ParentAgencyFields } from '../fields/ParentAgencyFields';

import { ClubDetailContentSkeleton } from './ClubDetailContentSkeleton';
import { MemberTypeField } from 'src/domains/club/members/grid/fields';
import React from 'react';

export const ClubDetailContent = () => {
  const record = useRecordContext();
  const theme = useTheme();

  const downloadContract = () => {
    if (!record || !record.club || !record.club.contractUrl) {
      console.error('No contract URL available');
      return;
    }

    const link = document.createElement('a');
    link.href = record.club.contractUrl as string;
    link.setAttribute('download', 'true');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const viewContract = () => {
    if (!record || !record.club || !record.club.contractUrl) {
      console.error('No contract URL available');
      return;
    }

    window.open(record.club.contractUrl as string, '_blank');
  };

  if (!record || record.department) {
    return <ClubDetailContentSkeleton />;
  }

  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <CardTitle text={t(tokens.domains.members.detail.ClubCardTitle)} />
      <Stack
        flexGrow="1"
        padding={'0px 24px 30px 24px'}
        spacing={4}
        marginRight="100px"
      >
        <Stack
          direction={'row'}
          spacing={3}
        >
          <Box flexBasis="25%">
            <LabeledField
              source={'club.name'}
              component={TextField}
              label={t(tokens.domains.members.detail.label.clubName)}
            />
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.helpdesk.detail.info.status)}>
              <FunctionField
                source="club.status"
                render={(record: Member) =>
                  record.club ? translateContractStatus(record.club.status) : '-'
                }
              />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.debitAmount)}>
              <FunctionField
                source="club.periodAmount"
                render={(record: Member) =>
                  record?.club?.periodAmount ? `${record.club.periodAmount} €` : '-'
                }
              />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.accountCreationDate)}>
              <FunctionField
                source="club.signedAt"
                render={(record: Member) =>
                  record.club ? dateToGridFormat(record.club.userCreatedAt) : '-'
                }
              />
            </TopLabelField>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
        >
          <Box flexBasis="25%">
            <AgencyFields record={record} />
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.type)}>
              <FunctionField
                label="Rôle"
                render={(memberRecord: Member) => {
                  const { isPresident, isFounding } = memberRecord.club || {};
                  return (
                    <MemberTypeField
                      isPresident={isPresident}
                      isFounding={isFounding}
                    />
                  );
                }}
              />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.paymentFrequency)}>
              <FunctionField
                source="club.signedAt"
                render={(record: Member) =>
                  record.club
                    ? translatePaymentPeriod({ paymentPeriod: record.club.paymentPeriod })
                    : '-'
                }
              />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.contractSignatureDate)}>
              <FunctionField
                source="club.signedAt"
                render={(record: Member) =>
                  record.club ? dateToGridFormat(record.club.signedAt) : '-'
                }
              />
            </TopLabelField>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
        >
          <Box flexBasis="25%">
            <ParentAgencyFields record={record} />
          </Box>
          <Box flexBasis="25%">
            <TopLabelField
              label={t(tokens.domains.members.detail.label.membershipContractSignatory)}
            >
              <FunctionField
                source="club.isSignatory"
                render={(record: Member) => (
                  <Chip
                    sx={{
                      backgroundColor:
                        record?.club && record?.club.isSignatory
                          ? theme.palette.success.main
                          : theme.palette.warning.main,
                    }}
                    label={record?.club ? isSignatory(record?.club.isSignatory) : '-'}
                  />
                )}
              />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.methodOfPayment)}>
              <TextField source={'user.club.paymentType'} />
              <FunctionField
                source="club.paymentType"
                render={(record: Member) =>
                  translatePaymentType(record.club ? record.club.paymentType : '-')
                }
              />
            </TopLabelField>
          </Box>
          <Box flexBasis="25%">
            <TopLabelField label={t(tokens.domains.members.detail.label.contract)}>
              <Stack
                direction={'row'}
                spacing={1}
              >
                <Chip
                  icon={<FileDownloadOutlinedIcon />}
                  label={t(tokens.domains.clubs.chips.download)}
                  variant="outlined"
                  color="primary"
                  sx={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                  }}
                  onClick={downloadContract}
                  disabled={!record?.club?.contractUrl}
                />
                <Chip
                  icon={<RemoveRedEyeIcon />}
                  label={t(tokens.domains.clubs.chips.see)}
                  variant="outlined"
                  color="primary"
                  sx={{
                    padding: '4px 8px',
                    cursor: 'pointer',
                  }}
                  onClick={viewContract}
                  disabled={!record?.club?.contractUrl}
                />
              </Stack>
            </TopLabelField>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};
