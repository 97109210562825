import { LocationOn } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

const AddressSearchSkeleton = () => {
  return (
    <Grid
      container
      sx={{ alignItems: 'center' }}
    >
      <Grid
        item
        sx={{ display: 'flex', width: 44 }}
      >
        <LocationOn sx={{ color: 'text.secondary' }} />
      </Grid>
      <Grid
        item
        sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
      >
        <Typography
          fontWeight={'bold'}
          component={'span'}
        >
          <Skeleton />
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          <Grid container>
            <Grid
              item
              lg={3}
            >
              <Skeleton />
            </Grid>
            <Grid
              item
              lg={1}
            >
              <Typography align={'center'}>-</Typography>
            </Grid>
            <Grid
              item
              lg={8}
            >
              <Skeleton />
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddressSearchSkeleton;
