import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Seo } from 'src/components/Seo';
import { UserListTable } from 'src/domains/admin/users/components/UserListTable';

// const useCustomersSearch = () => {
//     const [state, setState] = useState({
//         filters: {
//             query: undefined, hasAcceptedMarketing: undefined, isProspect: undefined, isReturning: undefined,
//         }, page: 0, rowsPerPage: 5, sortBy: 'updatedAt', sortDir: 'desc',
//     });
//
//     const handleFiltersChange = useCallback((filters) => {
//         setState((prevState) => ({
//             ...prevState, filters,
//         }));
//     }, []);
//
//     const handleSortChange = useCallback((sort) => {
//         setState((prevState) => ({
//             ...prevState, sortBy: sort.sortBy, sortDir: sort.sortDir,
//         }));
//     }, []);
//
//     const handlePageChange = useCallback((event, page) => {
//         setState((prevState) => ({
//             ...prevState, page,
//         }));
//     }, []);
//
//     const handleRowsPerPageChange = useCallback((event) => {
//         setState((prevState) => ({
//             ...prevState, rowsPerPage: parseInt(event.target.value, 10),
//         }));
//     }, []);
//
//     return {
//         handleFiltersChange, handleSortChange, handlePageChange, handleRowsPerPageChange, state,
//     };
// };
//
// const useCustomersStore = (searchState) => {
//     const isMounted = useMounted();
//     const [state, setState] = useState({
//         customers: [], customersCount: 0,
//     });
//
//     const handleCustomersGet = useCallback(async () => {
//         try {
//             const response = await customersApi.getCustomers(searchState);
//
//             if (isMounted()) {
//                 setState({
//                     customers: response.data, customersCount: response.count,
//                 });
//             }
//         } catch (err) {
//             console.error(err);
//         }
//     }, [searchState, isMounted]);
//
//     useEffect(() => {
//             handleCustomersGet();
//         }, // eslint-disable-next-line react-hooks/exhaustive-deps
//         [searchState]);
//
//     return {
//         ...state,
//     };
// };
//
// const useCustomersIds = (customers = []) => {
//     return useMemo(() => {
//         return customers.map((customer) => customer.id);
//     }, [customers]);
// };

const UserList = () => {
  return (
    <>
      <Seo title={'Liste des utilisateurs'} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">Liste des utilisateurs</Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  {/*<Button*/}
                  {/*  color="inherit"*/}
                  {/*  size="small"*/}
                  {/*  startIcon={*/}
                  {/*    <SvgIcon>*/}
                  {/*      <Upload01Icon />*/}
                  {/*    </SvgIcon>*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  Import*/}
                  {/*</Button>*/}
                  {/*<Button*/}
                  {/*  color="inherit"*/}
                  {/*  size="small"*/}
                  {/*  startIcon={*/}
                  {/*    <SvgIcon>*/}
                  {/*      <Download01Icon />*/}
                  {/*    </SvgIcon>*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  Export*/}
                  {/*</Button>*/}
                </Stack>
              </Stack>
              {/* <Stack
                alignItems="center"
                direction="row"
                spacing={3}
              >
                <Button
                  startIcon={
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Add
                </Button>
              </Stack> */}
            </Stack>
            <Card>
              {/*<CustomerListSearch*/}
              {/*  onFiltersChange={customersSearch.handleFiltersChange}*/}
              {/*  onSortChange={customersSearch.handleSortChange}*/}
              {/*  sortBy={customersSearch.state.sortBy}*/}
              {/*  sortDir={customersSearch.state.sortDir}*/}
              {/*/>*/}
              <UserListTable
              // count={customersStore.customersCount}
              // items={customersStore.customers}
              // onDeselectAll={clubSelection.handleDeselectAll}
              // onDeselectOne={clubSelection.handleDeselectOne}
              // onPageChange={customersSearch.handlePageChange}
              // onRowsPerPageChange={customersSearch.handleRowsPerPageChange}
              // onSelectAll={clubSelection.handleSelectAll}
              // onSelectOne={clubSelection.handleSelectOne}
              // page={customersSearch.state.page}
              // rowsPerPage={customersSearch.state.rowsPerPage}
              // selected={clubSelection.selected}
              />
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default UserList;
