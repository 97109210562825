import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React from 'react';
import {
  DateField,
  EditBase,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Ticket_GetTicketResource } from 'src/admin-api-types';
import { useMutationOptions } from 'src/common/hooks';
import { ContentCard } from 'src/common/layouts/material-ui';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import {
  TopLabelReferenceInput,
  TopLabelSelectInput,
} from 'src/components/react-admin/core/inputs';
import { AdminUserSearchInput } from 'src/components/react-admin/custom';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { getLocalHelpdeskTicketStatusList } from 'src/domains/club/helpdesk/data';
import { tokens } from 'src/locales/tokens';

export const HelpdeskTicketInformationCard = () => {
  const transform = <T,>(
    data: Partial<T>,
    options?: {
      previousData?: Partial<T>;
    }
  ): Partial<T> => {
    const previousData: Partial<T> = options?.previousData ?? {};

    const patchData: Partial<T> = {};

    Object.keys(data).forEach((key) => {
      const dataValue = data[key as keyof T];
      const prevDataValue = previousData[key as keyof T];

      if (dataValue !== prevDataValue) {
        patchData[key as keyof T] = dataValue;
      }
    });

    return patchData;
  };
  const mutationOptions = useMutationOptions({
    vertical: 'top',
    horizontal: 'center',
    successLabel: t(tokens.domains.helpdesk.detail.notify.success),
    failLabel: t(tokens.domains.helpdesk.detail.notify.fail),
  });
  const record = useRecordContext();
  return (
    <ContentCard title={t(tokens.domains.helpdesk.detail.info.title)}>
      <EditBase
        redirect={false}
        mutationMode={'pessimistic'}
        transform={transform}
        mutationOptions={mutationOptions}
      >
        <SimpleForm
          toolbar={false}
          sx={{ padding: 0 }}
        >
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
          >
            <Box>
              <TopLabelField
                component={TextField}
                label={t(tokens.domains.helpdesk.detail.info.nature)}
                source={'topic'}
              />
            </Box>
            <Box>
              <TopLabelSelectInput
                source={'status'}
                name={'status'}
                validate={required()}
                choices={getLocalHelpdeskTicketStatusList()}
                optionText={'label'}
                optionValue={'value'}
                label={t(tokens.domains.helpdesk.detail.info.status)}
              />
            </Box>
            <Box>
              <TopLabelField
                component={DateField}
                label={t(tokens.domains.helpdesk.detail.info.date)}
                source={'createdAt'}
              />
            </Box>
            <Box>
              <AdminUserSearchInput
                source={'managedBy'}
                label={t(tokens.domains.helpdesk.detail.info.manager)}
              />
            </Box>
            {record?.clubUser && (
              <Box>
                <UserAwareClubReferenceInput />
              </Box>
            )}

            <Grid
              container
              direction={'row-reverse'}
            >
              <Grid item>
                <SaveButton
                  variant={'contained'}
                  type={'submit'}
                  label={'Valider'}
                  icon={<></>}
                />
              </Grid>
            </Grid>
          </Stack>
        </SimpleForm>
      </EditBase>
    </ContentCard>
  );
};

const UserAwareClubReferenceInput = () => {
  const record = useRecordContext<Ticket_GetTicketResource>();
  return (
    <TopLabelReferenceInput
      label={t(tokens.domains.helpdesk.detail.info.club)}
      reference={ClubResourceEnum.CLUBS}
      filter={{ user: record?.clubUser }}
      recordRepresentation={'name'}
      source={'club'}
      name={'club'}
    />
  );
};

export default HelpdeskTicketInformationCard;
