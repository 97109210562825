import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/system/Stack';
import DefaultRenderOption from 'src/common/components/filters/SplitButtonFilter/DefaultRenderOption';
import SplitButtonFilterRenderOption, {
  AsSplitButtonFilterRenderOption,
} from 'src/common/components/filters/SplitButtonFilter/SplitButtonFilterRenderOption';
import { FilterOption } from 'src/common/types/filterOptionTypes';

interface SplitButtonComponentMenuProps {
  open: boolean;
  anchorRef: React.RefObject<HTMLDivElement>;
  renderOption?: React.FC<AsSplitButtonFilterRenderOption>;
  handleClose: (event: Event) => void;
  options: FilterOption[];
  selectedIndices: number[];
  handleMenuItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => void;
}

export const SplitButtonComponentMenu: React.FC<SplitButtonComponentMenuProps> = ({
  open,
  anchorRef,
  handleClose,
  options,
  selectedIndices,
  renderOption: RenderOption,
  handleMenuItemClick,
}) => {
  const theme = useTheme();
  return (
    <Popper
      sx={{
        zIndex: 1300,
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper
            sx={{
              boxShadow: theme.shadows[10],
              borderRadius: 1,
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                disablePadding
                autoFocusItem
                sx={{
                  '.MuiMenuItem-root': {
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'neutral.200',
                      color: 'neutral.500',
                    },
                    '&:hover': {
                      backgroundColor: 'neutral.200',
                    },
                    '&:first-of-type': {
                      borderRadius: 1,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                    '&:last-child': {
                      borderRadius: 1,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    },
                  },
                }}
              >
                {options.map((option: FilterOption, index) => (
                  <MenuItem
                    key={option.value}
                    dense
                    onClick={(event) => {
                      handleMenuItemClick(event, index);
                    }}
                    sx={{
                      fontSize: '0.9rem',
                    }}
                  >
                    <Stack
                      spacing={1}
                      direction={'row'}
                      alignItems={'center'}
                    >
                      <Checkbox
                        sx={{ paddingLeft: 0 }}
                        size={'small'}
                        checked={selectedIndices.indexOf(index) !== -1}
                      ></Checkbox>
                      {RenderOption ? (
                        <SplitButtonFilterRenderOption
                          component={RenderOption}
                          option={option}
                        />
                      ) : (
                        <DefaultRenderOption option={option} />
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
