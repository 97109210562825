import * as Sentry from '@sentry/react';
import { Dispatch, useCallback } from 'react';
import remoteAuthProvider from 'src/config/remoteAuthProvider';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  TWO_FACTOR_IN_PROGRESS,
} from 'src/contexts/auth/jwt/AuthProvider';
import {
  TwoFactorVerificationError,
  TwoFactorVerificationSuccess,
} from 'src/contexts/auth/jwt/authTypes';
import { useRouter } from 'src/hooks/useRouter';
import {
  RequestForgotPasswordResponseFront,
  ResetForgotPasswordResponseFront,
} from 'src/types/config';
import { ActionType, AuthAction, User } from 'src/types/contexts/auth';

import { checkLocalFullAuthentication } from '../../../../contexts/auth/jwt/authContext';

export const useAuthActions = (dispatch: Dispatch<AuthAction>) => {
  const router = useRouter();
  /**
   * Initializes the authentication process.
   * @returns {Promise<void>} A promise that resolves when the initialization is complete.
   */
  const initialize = useCallback(async () => {
    try {
      const locallyAuthenticated = checkLocalFullAuthentication();
      if (locallyAuthenticated) {
        const user = await remoteAuthProvider.me();
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticatedFully: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticatedFully: false,
            user: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticatedFully: false,
          user: null,
        },
      });
      throw new Error('Error initializing authentication');
    }
  }, [dispatch]);

  /**
   * Performs a login action with the provided email and password.
   * @param {string} email - The user's email.
   * @param {string} password - The user's password.
   * @returns {Promise<void>} - A promise that resolves when the login action is complete.
   */
  const login = useCallback(
    async (email: string, password: string) => {
      try {
        const { two_factor_complete } = await remoteAuthProvider.login({
          email,
          password,
        });
        localStorage.setItem(TWO_FACTOR_IN_PROGRESS, two_factor_complete.toString());
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            isAuthenticatedTwoFactorInProgress: true,
          },
        });
      } catch (err) {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticatedFully: false,
            user: null,
          },
        });
        const errorMessage = err instanceof Error ? err.message : 'An error occurred';
        throw new Error(errorMessage);
      }
    },
    [dispatch]
  );

  /**
   * Verifies the two-factor authentication code and performs necessary actions.
   * @param {string} code - The two-factor authentication code.
   * @returns {Promise<void>} - A promise that resolves when the verification is complete.
   */
  const verifyTwoFactor = useCallback(
    async (code: string): Promise<TwoFactorVerificationSuccess | TwoFactorVerificationError> => {
      try {
        const response = await remoteAuthProvider.verifyTwoFactorCode(code);
        if ('token' in response) {
          const { token: accessToken, refresh_token: refreshToken } = response;
          localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
          localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
          localStorage.removeItem(TWO_FACTOR_IN_PROGRESS);
          const user = await getUserData();

          dispatch({
            type: ActionType.SIGN_IN_2FA,
            payload: {
              user,
            },
          });
          return { token: accessToken, refresh_token: refreshToken };
        } else {
          const { error, two_factor_complete } = response;
          return { error, two_factor_complete };
        }
      } catch (err: unknown) {
        if (
          err instanceof Error &&
          err.message === "Erreur lors de l'obtention des données utilisateur"
        ) {
          throw err;
        } else {
          const errorMessage = err instanceof Error ? err.message : 'An error occurred';
          throw new Error(errorMessage);
        }
      }
    },
    [dispatch]
  );

  /**
   * Logs out the user by removing access token, refresh token, and two-factor authentication progress from local storage.
   * Dispatches a SIGN_OUT action and refreshes the router.
   */
  const logout = useCallback(async () => {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(TWO_FACTOR_IN_PROGRESS);
    Sentry.setUser(null); // Set the user to null in Sentry
    dispatch({ type: ActionType.SIGN_OUT });
    router.refresh();
  }, [dispatch, router]);

  const requestForgotPassword = useCallback(
    async (email: string): Promise<RequestForgotPasswordResponseFront> => {
      const result = await remoteAuthProvider.requestForgotPassword(email);
      return result;
    },
    []
  );

  /**
   * Resets the forgotten password for a user.
   *
   * @param password - The new password.
   * @param token - The reset token.
   * @param email - The user's email.
   * @returns A promise that resolves to a ResetForgotPasswordResponseFront object.
   * @throws If an error occurs during the password reset process.
   */
  const resetForgotPassword = useCallback(
    async (
      password: string,
      token: string,
      email: string
    ): Promise<ResetForgotPasswordResponseFront> => {
      try {
        const result = await remoteAuthProvider.resetForgotPassword(password, token);
        dispatch({
          type: ActionType.RESET_PASSWORD,
          payload: {
            email,
            passwordReset: result.passwordReset,
          },
        });
        return result;
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'An error occurred';
        throw new Error(errorMessage);
      }
    },
    [dispatch]
  );

  /**
   * Retrieves the user data asynchronously.
   * @returns {Promise<User>} The user data.
   * @throws {Error} If there is an error retrieving the user data.
   */
  const getUserData = async (): Promise<User> => {
    try {
      const user = await remoteAuthProvider.me();
      return user;
    } catch (error) {
      throw new Error("Erreur lors de l'obtention des données utilisateur");
    }
  };

  return { initialize, login, verifyTwoFactor, logout, requestForgotPassword, resetForgotPassword };
};
