export enum ClubSourceEnum {
  NAME = 'name',
  PRESIDENT = 'president',
  AGENCY_RESOURCE = 'agencyResource',
  FORMAT = 'format',
  DEPARTMENT = 'department',
  STATUS = 'status',
  COUNT_ACTIVE_MEMBERS = 'activeMembers',
  CREATED_AT = 'createdAt',
  MEMBER = '@id',
  ORGANIZATION = 'organization',
  CLUB = 'club',
  AGENCY = 'agency',
  PROFILE = 'profile',
  PRESIDENT_ORGANIZATION = 'presidentOrganization',
  PARENT_AGENCY = 'parentAgency',
  CLUB_SETTING = 'clubSetting',
  CONTRACT = 'contract',
}
