import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/domains/system/auth/guards/GuestGuard';
import { Layout as AuthLayout } from 'src/domains/system/auth/layouts/Layout';

// JWT
const JwtLoginPage = lazy(() => import('src/domains/system/auth/login'));
const TwoFactorVerificationPage = lazy(() => import('src/domains/system/auth/TwoFactor'));
const RequestPasswordPage = lazy(() => import('src/domains/system/auth/requestPassword'));
const ResetPasswordPage = lazy(() => import('src/domains/system/auth/resetPassword'));

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <JwtLoginPage />,
      },
      {
        path: '2fa',
        element: <TwoFactorVerificationPage />,
      },
      {
        path: 'forgot-password/request',
        element: <RequestPasswordPage />,
      },
      {
        path: 'forgot-password/reset',
        element: <ResetPasswordPage />,
      },
    ],
  },
];
