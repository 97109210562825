import Chip from '@mui/material/Chip';
import React from 'react';
import { RaRecord, useRecordContext } from 'react-admin';

type ChipColor = 'success' | 'warning' | 'error' | 'default' | 'primary' | 'secondary' | 'info';

interface YesNoChipFieldProps {
  source: string;
  yesText?: string;
  noText?: string;
  yesColor?: ChipColor;
  noColor?: ChipColor;
  condition?: (value: string | boolean | object) => boolean;
  label?: string;
}

export const YesNoChipField: React.FC<YesNoChipFieldProps> = ({
  source,
  yesText = 'Oui',
  noText = 'Non',
  yesColor = 'success',
  noColor = 'warning',
  condition = (value) => value === true,
}) => {
  const record = useRecordContext<RaRecord>();
  const conditionValue = record[source] as string | boolean | object | undefined;

  const isCondition: boolean | undefined =
    conditionValue !== undefined ? condition(conditionValue) : undefined;

  const chipLabel: string = isCondition !== undefined ? (isCondition ? yesText : noText) : '?';
  const chipColor: ChipColor =
    isCondition !== undefined ? (isCondition ? yesColor : noColor) : 'error';

  return (
    <Chip
      sx={{
        borderRadius: 1,
      }}
      label={chipLabel}
      color={chipColor}
    />
  );
};
