// import { Stack } from '@mui/material';
// import { t } from 'i18next';
// import { tokens } from 'src/locales/tokens';
// import { BasicButtonLink } from 'src/common/components/BasicButtonLink';
import { useListExportModalContext } from 'src/common/modals/hooks/useListExportModalContext';
import { AsyncFeedbackProvider } from 'src/contexts/modals/async-feedback-provider';

import { BaseModal } from '../generic/base-modal';

import { ExportsList } from './ExportsList';
import { NewExportWithAsyncFeedback } from './NewExport';

interface ListExportModalProps {
  resource: string;
}

export const ListExportModal: React.FC<ListExportModalProps> = ({ resource }) => {
  const { isOpen, closeModal } = useListExportModalContext();

  return (
    <BaseModal
      isOpen={isOpen}
      closeModal={closeModal}
      ariaLabelledby="modal-modal-title"
      ariaDescribedby="modal-modal-description"
    >
      <NewExportWithAsyncFeedback resource={resource} />
      <AsyncFeedbackProvider>
        <ExportsList resource={resource} />
      </AsyncFeedbackProvider>
      {/* <Stack alignItems={'flex-end'}>
        <BasicButtonLink
          path={'/clubs'} //TODO This path should be updated when /exports is available
          anchor={t(tokens.modale.export.exportsPageLink)}
        />
      </Stack> */}
    </BaseModal>
  );
};
