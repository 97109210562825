import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { IconButtonWithMenu } from 'src/common/components/IconButtonWithMenu';
import { useExportActions } from 'src/common/modals/hooks/useExportActions';
import { usePopover } from 'src/hooks/usePopover';
import { ListExport } from 'src/types/contexts/modals';

interface ExportListItemHeaderProps {
  item: ListExport;
}

export const ExportListItemHeader: React.FC<ExportListItemHeaderProps> = ({ item }) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover();
  const actions = useExportActions(item, handleClose).map((action) => ({
    label: action.label,
    onClick: action.onClick,
    disabled: action.disabled,
    icon: action.icon,
  }));
  return (
    <Box
      display="flex"
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        padding: '5px',
      }}
    >
      <Box
        display="flex"
        sx={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <TextSnippetOutlinedIcon />
        <Typography marginLeft={2}>{item.name}</Typography>
      </Box>
      <IconButtonWithMenu
        icon={<MoreVertIcon />}
        actions={actions}
        anchorRef={anchorRef}
        handleClose={handleClose}
        open={open}
        handleOpen={handleOpen}
      />
    </Box>
  );
};
