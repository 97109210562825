import { Box, Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';
import { tokens } from 'src/locales/tokens';

export const ContractSent: React.FC = () => {
  return (
    <>
      <CardTitle text={t(tokens.domains.contract.stepThree.title)} />
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
        >
          <Stack
            direction="column"
            width="fit-content"
          >
            <Typography
              variant="body2"
              data-testid="typo-one"
            >
              {t(tokens.domains.contract.stepThree.typoOne)}
            </Typography>
            <Typography
              variant="body2"
              data-testid="typo-two"
            >
              {t(tokens.domains.contract.stepThree.typoTwo)}
            </Typography>
            <Typography
              variant="body2"
              data-testid="typo-three"
            >
              {t(tokens.domains.contract.stepThree.typoThree)}
            </Typography>
          </Stack>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              LinkComponent={'a'}
              href="/club/contracts/create"
              data-testid="contract-button"
            >
              {t(tokens.domains.contract.stepThree.button)}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
