import Stack from '@mui/system/Stack';
import { Datagrid, useListContext } from 'react-admin';
import { EmptyResult } from 'src/common/components/grid/EmptyResult';
import { Scrollbar } from 'src/components/scrollbar';
import { useCssVars } from 'src/hooks/theme/useCssVarsDesktop';
import { useSettings } from 'src/hooks/useSettings';

interface DatagridLayout {
  children: React.ReactNode[];
  skeleton?: React.FC;
  content?: React.ReactNode;
}

export const DatagridLayout: React.FC<DatagridLayout> = ({
  children,
  skeleton: Skeleton,
  content,
}) => {
  const { isFetching, isLoading } = useListContext();
  const { navColor: color } = useSettings();

  const cssVars = useCssVars(color);
  return (
    <Stack
      spacing={2}
      sx={{ ...cssVars }}
    >
      <Scrollbar
        sx={{
          width: '100%',
          '& .simplebar-content': {
            width: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        {(!isFetching && !isLoading) || Skeleton === undefined ? (
          <Datagrid
            bulkActionButtons={false}
            empty={<EmptyResult {...{ content }} />}
          >
            {children}
          </Datagrid>
        ) : (
          <Skeleton />
        )}
      </Scrollbar>
    </Stack>
  );
};
