type NestedRecord = {
  [key: string]: NestedRecord | unknown;
};

export function getNestedProperty<T>(record: NestedRecord, source: string): T | null {
  if (!record || !source) {
    return null;
  }

  const keys = source.split('.');
  let current: NestedRecord | unknown = record;

  for (const key of keys) {
    if (
      typeof current !== 'object' ||
      current === null ||
      !(key in current) ||
      (current as NestedRecord)[key] === undefined
    ) {
      return null;
    }
    current = (current as NestedRecord)[key];
  }

  return current as T;
}
