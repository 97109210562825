import { t } from 'i18next';
import React from 'react';
import { DatePickerFilter, SearchFilter, SplitButtonFilter } from 'src/common/components/filters';
import ResetFiltersButton from 'src/common/components/ResetFiltersButton';
import { TagSummary } from 'src/common/components/TagSummary';
import { TotalItems } from 'src/common/components/TotalItems';
import { FilterLayout } from 'src/common/layouts/react-admin/FilterLayout';
import TaggedFilter from 'src/contexts/common/filters/tagged-filter-context/components/TaggedFilter';
import { RegistrationSearchFilterEnum } from 'src/data/enum/local/searchFilterEnum';
import { useDatePickerRangeHandler } from 'src/hooks/useDatePickerRangeHandler';
import { useReactAdminFilters } from 'src/hooks/useReactAdminFilters';
import { tokens } from 'src/locales/tokens';
import { FilterLabels, FilterValues } from 'src/types/enums';
import { getDateYearsAgo } from 'src/utils/getDateYearsAgo';

import { clubMembershipStatusOptions, Option } from '../../utils/clubMembershipStatusOptions';

export const ClubMembershipFilters: React.FC = () => {
  const { filterValues, setFilters } = useReactAdminFilters();
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickerRangeHandler();

  return (
    <FilterLayout>
      <>
        <SearchFilter
          filterName={RegistrationSearchFilterEnum.SEARCH_TERM}
          filterValues={filterValues}
          placeholder={t(tokens.placeholder.memberSearchByName)}
        />
      </>
      <>
        <TotalItems />
      </>
      <>
        <TaggedFilter
          component={SplitButtonFilter}
          label={FilterLabels.Status}
          value={FilterValues.STATUS}
          options={clubMembershipStatusOptions as Option[]}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter}
          label={FilterLabels.CreatedAt}
          value={FilterValues.MODIFY_AT_AFTER}
          minDate={getDateYearsAgo(15)}
          maxDate={endDate}
          setStartDate={setStartDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter}
          minDate={startDate}
          label={FilterLabels.EndAt}
          value={FilterValues.MODIFY_AT_BEFORE}
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <ResetFiltersButton
          setFilters={setFilters}
          filterValues={filterValues}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </>
      <>
        <TagSummary
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
      </>
    </FilterLayout>
  );
};
