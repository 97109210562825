import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { RouterLink } from 'src/components/RouterLink';
import { Seo } from 'src/components/Seo';
import { paths } from 'src/paths';

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Error: Not Found" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 'calc(100vh - 64px)',
          backgroundImage: 'url("/assets/errors/error-404-background.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          py: '80px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            textAlign: 'center',
            color: 'white',
          }}
        >
          <Typography
            fontSize={'128px'}
            sx={{
              fontFamily: '"Concert One", sans-serif',
              color: 'inherit',
              lineHeight: '1',
            }}
          >
            404
          </Typography>
          <Typography
            fontSize={'64px'}
            sx={{
              fontFamily: '"Concert One", sans-serif',
              color: '#9E9BB2',
            }}
          >
            Page non trouvée
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            p: 4,
          }}
        >
          <Button
            component={RouterLink}
            href={paths.index}
            sx={{
              color: 'white',
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                marginRight: '36px',
                fontSize: '48px',
                transform: 'scaleX(1.5)',
                marginTop: '5px',
              }}
            />
            <Typography sx={{ fontFamily: '"Concert One", sans-serif', fontSize: '37px' }}>
              Revenir à l&apos;accueil
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NotFoundPage;
