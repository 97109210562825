import { AccessRights } from 'src/types/pages/users';
/**
 * The function `allowedAccessRights` filters and maps allowed access rights from a given array of
 * access rights groups.
 * @param {AccessRights | null} accessRights - The `accessRights` parameter in the
 * `allowedAccessRights` function is of type `AccessRights | null`. It represents an array of objects
 * where each object contains a `group` property, which is also an object with an `accessRights`
 * property. The `accessRights` property is an
 * @returns The function `allowedAccessRights` takes an array of `AccessRights` or `null` as input and
 * returns an object with a key `accessRights` whose value is an array of allowed access rights codes.
 * If the input `accessRights` is `null`, it returns an object with an empty array for `accessRights`.
 * If the input `accessRights` is not `null`, it
 */

export const allowedAccessRights = (accessRights: AccessRights | null) => {
  if (!accessRights) {
    return { accessRights: [] };
  }
  const codes = accessRights.reduce((acc: AccessRights, group) => {
    const allowedRightsInGroup = group.accessRights
      .filter((right) => right.allowed)
      .map((right) => '/access_rights/' + right.code);
    return acc.concat(allowedRightsInGroup as unknown as AccessRights);
  }, []);

  return { accessRights: codes };
};
